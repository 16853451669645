<template>
  <div>
    <div class="header">
      <van-nav-bar
        :title="$t('gjrz.title')"
        left-arrow
        @click-left="$router.go(-1)"
      />
    </div>
    <div class="maincontent">
      <van-row class="spacing">
        <van-form ref="form">
          <van-row class="field">
            <van-field
              v-model="identity.profession"
              :label="$t('gjrz.jobs')"
              :placeholder="$t('gjrz.notice1')"
              :rules="[{ required: true, message: $t('该项是必填') }]"
            />
          </van-row>
          <van-row class="field">
            <van-field
              v-model="identity.income"
              :label="$t('gjrz.income')"
              :placeholder="$t('gjrz.notice2')"
              :rules="[{ required: true, message: $t('该项是必填') }]"
            />
          </van-row>
         <!-- <van-row class="field">
            <van-field
              v-model="identity.contact_number"
              :label="$t('gjrz.phone')"
              :placeholder="$t('gjrz.notice3')"
              :rules="[{ required: true, message: $t('该项是必填') }]"
            />
          </van-row> -->

          <van-row class="message">{{ $t('gjrz.sfzzm') }}</van-row>
          <van-row class="uploading-image">
            <van-uploader :beforeRead="beforeRead" :after-read="aftersfza">
              <img
                :src="sfza ? sfza : ''"
                ref="sfza"
                v-if="sfza"
                style="max-width:100%;max-height:10rem;text-align:center"
              />
            </van-uploader>
          </van-row>
          <van-row class="message">{{ $t('gjrz.sfzfm') }}</van-row>
          <van-row class="uploading-image">
            <van-uploader :beforeRead="beforeRead" :after-read="aftersfzb">
              <img
                :src="sfzb ? sfzb : ''"
                ref="sfzb"
                v-if="sfzb"
                style="max-width:100%;max-height:10rem;text-align:center"
              />
            </van-uploader>
          </van-row>
          <van-row class="message">{{ $t('gjrz.sczj') }}</van-row>
          <van-row class="uploading-image">
            <van-uploader :beforeRead="beforeRead" :after-read="aftersczj">
              <img
                :src="sczj ? sczj : ''"
                ref="sczj"
                v-if="sczj"
                style="max-width:100%;max-height:10rem;text-align:center"
              />
            </van-uploader>
          </van-row>
          <van-row class="message">{{ $t('gjrz.qtzm') }}</van-row>
          <van-row class="uploading-image">
            <van-uploader :beforeRead="beforeRead" :after-read="afterqtzm">
              <img
                :src="qtzm ? qtzm : ''"
                ref="qtzm"
                v-if="qtzm"
                style="max-width:100%;max-height:10rem;text-align:center"
              />
            </van-uploader>
          </van-row>
        </van-form>
        <van-button
          v-if="identity.oauth === '2'"
          class="submitBtn"
          @click.prevent="submit"
          >{{ $t('common.submit') }}</van-button
        >
        <van-button v-if="identity.oauth === '3'" class="submitBtn">{{
          $t('gjrz.auth1')
        }}</van-button>
        <van-button v-if="identity.oauth === '4'" class="submitBtn">{{
          $t('gjrz.auth2')
        }}</van-button>
      </van-row>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      identity: {},
      rules: [],
      sfza: '',
      sfzb: '',
      sczj: '',
      qtzm: ''
    }
  },
  created() {
    this.getinfo()
  },
  methods: {
    async getinfo() {
      const { data } = await this.$http.get('/home/home/getindentity')
      if (data) {
        if (data.code === 200) {
          this.identity = data.data
          if (parseInt(data.data.oauth) < 2) {
            this.$router.push('/person/authentication')
          }
        }
      }
    },
    beforeRead(file) {
      if (
        file.type !== 'image/jpeg' &&
        file.type !== 'image/png' &&
        file.type !== 'image/gif'
      ) {
        this.$toast(this.$t('gjrz.toast'))
        return false
      }
      return true
    },
    async aftersfza(file) {
      const param = new FormData()
      param.append('file', file.file)
      const { data } = await this.$http.post('/home/user/uploadimg', param)
      if (data) {
        this.sfza = data.data.url
      }
    },
    async aftersfzb(file) {
      const param = new FormData()
      param.append('file', file.file)
      const { data } = await this.$http.post('/home/user/uploadimg', param)
      if (data) {
        this.sfzb = data.data.url
      }
    },
    async aftersczj(file) {
      const param = new FormData()
      param.append('file', file.file)
      const { data } = await this.$http.post('/home/user/uploadimg', param)
      if (data) {
        this.sczj = data.data.url
      }
    },
    async afterqtzm(file) {
      const param = new FormData()
      param.append('file', file.file)
      const { data } = await this.$http.post('/home/user/uploadimg', param)
      if (data) {
        this.qtzm = data.data.url
      }
    },
    // 提交认证
    submit() {
      this.$refs.form
        .validate()
        .then(async () => {
          const { data } = await this.$http.post('/home/home/advanced', {
            profession: this.identity.profession,
            income: this.identity.income,
            contact_number: this.identity.contact_number,
            sfza: this.sfza,
            sfzb: this.sfzb,
            sczj: this.sczj,
            qtzm: this.qtzm
          })
          if (data) {
            if (data.code === 200) {
              this.$toast.success(this.$t('common.success'))
              setTimeout(() => {
                this.$router.push('/index')
              }, 300)
            } else {
              this.$$toast.fail(
                this.getlang(data.msg, localStorage.getItem('lang'))
              )
            }
          }
        })
        .catch(() => {
          return false
        })
    }
  }
}
</script>

<style lang="less" scoped>
.maincontent {
  padding-top: 50px;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  font-size: 0.93333rem;
  background: #f7f7f7;
  height: 100vh;
  .spacing {
    margin-top: 1rem;
    .field {
      background-color: #f1f1f2;
      margin-top: 0.53333rem;
      width: 90%;
      margin-left: 5%;
      border-radius: 0.53333rem;
      span {
        color: #000;
        font-size: 1.86667rem;
      }
      .van-cell {
        font-size: 0.98667rem;
        background-color: #f1f1f2 !important;
        color: #fff !important;
      }
      .van-field__control {
        padding-right: 1.2rem;
      }
      /deep/.van-field input {
        color: #000;
        text-align: left;
      }
      /deep/.van-field__error-message {
        text-align: center;
      }
    }
    .submitBtn {
      margin-top: 10%;
      width: 80%;
      letter-spacing: 0.53333rem;
      height: 4rem;
      font-size: 0.93333rem;
      color: #fff;
      background-color: #004ea3;
      border-radius: 0.53333rem;
      border: none;
      box-shadow: 0 0 1.33333rem #004ea3;
    }
  }
  .uploading-image {
    height: 10rem;
    width: 80%;
    margin: 2% 10% 5% 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0.02667rem dashed grey;
    .van-uploader {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      /deep/.van-uploader__wrapper {
        width: 100%;
        height: 100%;
        justify-content: center;
      }
      /deep/.van-uploader__upload {
        width: 100%;
        height: 100%;
        margin: 0;
        background-color: #fff;
        color: #fff;
      }
    }
  }
}
</style>
